import './style.scss';

const Error = () => {
  document.title = "Error Page"
  return (
    <div className="view-error">
        <h1>404 page not found</h1>
    </div>
  )
}

export default Error