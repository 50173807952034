import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import your route components too
// import App from "./views/home/home";

// import Auth from "./views/auth/auth.js";
import Login from "./views/login/login.js";
// import Logout from "./views/logout/logout.js";

// Private
// import Profil from "./views/protected/profil/profil.js";
// import Main from "./views/protected/main/main.js";

import Error from "./views/error/error.js";

// Utils
// import PrivateRoute from './utils/PrivateRoute'
// import IsConnect from './utils/IsConnect'

render(
  <BrowserRouter>
    <Routes>
      {/* <Route exact path="/" redirect={<Login />}> */}
        {/* <Route index element={<App />}/> */}
        {/* <Route element={<PrivateRoute />}> */}
        {/* <Route path="/main" element={<Main />}/> */}
        {/* <Route path="/profil" element={<Profil />}/> */}
        {/* </Route> */}
        {/* <Route element={<IsConnect />}> */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/logout" element={<Logout />} /> */}
        {/* </Route> */}
      {/* </Route> */}
      {/* <Route path="/auth" element={<Auth />}/> */}
      <Route path="*" element={<Error />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
